define("ptportal/helpers/eq", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function eq([value1, value2]) {
    return value1 === value2;
  });

  _exports.default = _default;
});